<template>
	<div class="p-grid" >
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">{{$t('Costo e Impuesto')}}</h5>
						</div>
					</template>
					<Column field="name" :header="$t('Nombre')" :sortable="true" headerStyle="width: 80px">
						<template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">
                                {{slotProps.data.nombre}}
                            </div>
                            <div v-else>
                                {{slotProps.data.name}}
                            </div>
						</template>
					</Column>
					<Column field="costo1" :header="$t('Costo')" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
                            <InputNumber id="horizontal" v-model="slotProps.data.costo1" showButtons buttonLayout="horizontal" 
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
                                decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="max-width:25px;"/>
						</template>
					</Column>
					<Column field="impuesto" :header="$t('Impuesto')" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
                            <InputNumber id="horizontal" v-model="slotProps.data.impuesto" showButtons buttonLayout="horizontal"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
                                decrementButtonIcon="pi pi-minus" prefix="%" min="0" style="max-width:25px;"/>
						</template>
					</Column>
					<Column field="activo" :header="$t('Estatus')" :sortable="true" headerStyle="width: 80px">
						<template #body="slotProps">
							<InputSwitch v-model="slotProps.data.activo" />
						</template>
					</Column>
					<template #footer>
						<div class="p-grid p-jc-end">
                            <Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						</div>
					</template>
				</DataTable>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
    import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
                i18n: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				filters: {},
				submitted: false,
				size: '60vw',
				niveles: null,
			}
		},
		productService: null,
		created() {
            this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = null;
				this.$store.commit('Loading');
				const Consulta = new API('Configuracion');
				Consulta.Ini('TipoPoliza').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.tipos;
					this.$store.commit('Loading');
				}); 
			},
			saveProduct() {
                this.$store.commit('Loading');
				const Consulta = new API('Configuracion');          
				Consulta.Procesar('TipoPoliza',{
					caso: 	'UPD',
					products: 	this.products,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
                        this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                    this.$store.commit('Loading');
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
